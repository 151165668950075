/**
 * Created by BETALOS on 08/07/2017.
 */
(function () {

    'use strict';

    module.exports = passwordConfirm;

    passwordConfirm.$inject = [];

    function passwordConfirm() {

        var directive = {
            restrict: 'A',
            priority: 0,
            require: 'ngModel',
            link: link,
        };

        function link(scope, element, attrs, ctrl) {
            scope.$watch(attrs.passwordConfirm, function (newValue) {
                if (ctrl && ctrl.$modelValue) {
                    if (newValue == ctrl.$modelValue) {
                        ctrl.$setValidity('passwordConfirm', true);
                        // return newValue;
                    } else {
                        ctrl.$setValidity('passwordConfirm', false);
                        // return undefined;
                    }
                }
            });

            ctrl.$parsers.unshift(function (viewValue) {
                if (viewValue) {
                    if (viewValue == scope.$eval(attrs.passwordConfirm)) {
                        ctrl.$setValidity('passwordConfirm', true);
                        return viewValue;
                    } else {
                        ctrl.$setValidity('passwordConfirm', false);
                        return undefined;
                    }
                } else {
                    ctrl.$setValidity('passwordConfirm', true);
                    return viewValue;
                }
            });
        };

        return directive;
    };

})()