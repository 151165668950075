/**
 * Created by Hp on 03/08/2017.
 */

(function () {
    'use strict';
    module.exports = ContractFormCtrl;

    ContractFormCtrl.$inject = ["$stateParams", "$q", "patientService", "$translate", "system", "$mdDialog", 'billingService', '$state', 'lotService', 'articleService', '$scope', '$auth', '$element', 'tariffService'];

    function ContractFormCtrl($stateParams, $q, patientService, $translate, system, $mdDialog, billingService, $state, lotService, articleService, $scope, $auth, $element, tariffService) {
        let vm = this;
        let currentContractId = $stateParams['contractId'];
        let contractType = $stateParams['type'];
        let duplicateObject = $stateParams['duplicateObject'];
        let dateFormat = system['date_format'].js;

        vm.$onInit = init;
        vm.submit = submit;
        vm.removePatient = removePatient;
        vm.patientCallback = patientCallback;
        vm.onPaginate = onPaginate;
        vm.selectArticle = selectArticle;
        vm.getContractAmount = getContractAmount;
        vm.getSubTotal = getSubTotal;
        vm.prePrint = prePrint;

        vm.serialButtonClick = serialButtonClick;
        vm.lotButtonClick = lotButtonClick;
        vm.sumLotsQte = sumLotsQte;
        vm.updateWarehouse = updateWarehouse;
        vm.handlePlan = handlePlan;
        vm.removeLine = removeLine;
        vm.validate = validate;
        vm.terminate = terminate;
        vm.removeContract = removeContract;
        vm.deactivate = deactivate;
        vm.getRelationLabel = getRelationLabel;
        vm.duplicate = duplicate;
        vm.renewContract = renewContract;

        vm.goToPayment = billingService.payContract;

        vm.relations = [];

        vm.noPatientImage = [];
        vm.relationTypes = [
            {value: 'PARENT', label: "patient_relations_parent", index: 0},
            {value: 'SPOUSE', label: "patient_relations_spouse", index: 1},
            {value: 'CHILD', label: "patient_relations_child", index: 2},
            {value: 'SIBLING', label: "patient_relations_sibling", index: 3},
            {value: 'NURSE', label: "patient_relations_nurse", index: 4}
        ];

        vm.contractHistoric = contractHistoric;
        vm.handlePricingChange = handlePricingChange;
        vm.handleRenewalPeriodChange = handleRenewalPeriodChange;
        vm.handleCommentClick = handleCommentClick;

        function init() {
            vm.filter = {is_deleted: {"$ne": true}};
            vm.query = {
                limit: 5,
                page: 1,
                order: "-id"
            };
            vm.options = [5, 10, 20, 50];
            vm.paginationLabel = {
                page: $translate.instant('page'),
                rowsPerPage: $translate.instant('rowsPerPage'),
                of: $translate.instant('of')
            };
            let promises = [articleService.warehousesList()]
            if (!_.isNil(currentContractId)) {
                billingService.getContract(currentContractId).then(success);
            } else {
                vm.contract = !_.isNil(duplicateObject) ? duplicateObject : {
                    start_at: moment().format(dateFormat),
                    doc_date: moment().format(dateFormat),
                    adhesion_date: moment().format(dateFormat),
                    billing_details: {
                        bill_period: 'YEAR',
                        bill_nbr: 1,
                        renewal_nbr: 1,
                        renewal_period: 'YEAR',
                        auto_renew: true,
                        free_trial: 0,
                        automatic_close_limit: 0,
                        expired_cycles: 1
                    },
                    operator: null,
                    payer_type: "P",
                    details: [],
                    type: contractType || 'SUBSCRIPTION'
                };
                if (!_.isNil(vm.contract.beneficiary)) {
                    handleBeneficiaryRelations(vm.contract.beneficiary);
                }
            }

            function success(data) {
                vm.contract = data;
                patientService.nextPatient(vm.contract.beneficiary.id)
                    .then(data => {
                        vm.nextPatient = data;
                    }, _.noop)
                if (!_.isNil(vm.contract.beneficiary)) {
                    handleBeneficiaryRelations(vm.contract.beneficiary);
                }
            }
        }

        function submit(with_cancel) {
            if (!vm.prevent_save) {
                vm.prevent_save = true;
                vm.promise = billingService.saveContract(vm.contract).then(success);
            }

            function success(data) {
                vm.prevent_save = false;
                if (with_cancel) {
                    $state.go("app.billing.contract", {})
                } else {
                    saveSuccess(data);
                }
            }
        }

        function saveSuccess(data) {
            vm.contract = data;
            vm.prevent_validate = false;
            if (_.isNil(currentContractId)) $state.go('app.billing.contract-form', {contractId: data.id}, {location: 'replace'});

        }

        function removePatient() {
            vm.contract.beneficiary = null;
        }

        function patientCallback(patient) {
            vm.contract.beneficiary = patient;
            if (!vm.contract.city) {
                vm.contract.city = _.get(patient, 'contact_info.city');
            }
            vm.contract.beneficiary = patient;
            if (!_.isNil(vm.contract.beneficiary)) {
                if (_.isEmpty(vm.contract.folder_number) && !_.isNil(vm.contract.beneficiary.file_number)) {
                    vm.contract.folder_number = vm.contract.beneficiary.file_number;
                }
                handleBeneficiaryRelations(vm.contract.beneficiary);
            }
        }

        function onPaginate(page, limit) {
            vm.query = _.assign(vm.query, {page: page, limit: limit});
            getData();
        }

        function getData() {
            if (vm.contract.details.length < ((vm.query.page - 1) * vm.query.limit)) {
                vm.query.page = 1;
            }
        }

        function selectArticle(ev) {
            $mdDialog.show(_.assign(require('stock/dialogs/article-search-dialog'), {
                targetEvent: ev,
                locals: {
                    search: {interdict_sale: {$ne: true}, is_deleted: {$ne: true}}
                }
            })).then(selectArticleCallBack);
        }

        function selectArticleCallBack(data) {
            handleRelationsMap();
            _.forEach(data, function (article) {
                if (_.isNil(_.find(vm.contract.details, {code: article.code}))) {
                    vm.contract.details.push({
                        code: article.code,
                        description: article.short_desc,
                        qte: 1,//vm.minors_age.length + (vm.spouses_nbr > 0 ? 1 : 1)
                        price: vm.contract.tariff ? handleArticlePrice(article, _.get(article, 'sale_price', 0)) : _.get(article, 'sale_price', 0),//article.sale_price
                        article: _.cloneDeep(article)
                    });
                }
            });
        }

        function handleRelationsMap() {
            vm.minors_age = _.chain(vm.relations).filter((e) => {
                return e.relation == 'CHILD'
            }).map((e) => {
                return moment().diff(moment(e.member.birth_date, dateFormat), 'years')
            }).value();
            vm.spouses_nbr = _.get(_.countBy(vm.relations, {relation: 'SPOUSE'}), 'true', 0);
            vm.other_relations = _.chain(vm.relations).filter((e) => {
                return e.relation == 'NURSE'
            }).map('relation').value();
        }

        function handleArticlePrice(article, price) {
            let p = 0;
            _.forEach(vm.minors_age, (a) => {
                p += tariffService.handlePrice(price, vm.contract.tariff, article, "Article", "stock.models", 'age', a)
            });
            _.forEach(vm.other_relations, (a) => {
                p += tariffService.handlePrice(price, vm.contract.tariff, article, "Article", "stock.models", 'relation', a)
            });
            p += tariffService.handlePrice(price, vm.contract.tariff, article, "Article", "stock.models", 'relations_spouse', vm.spouses_nbr)
            return p
        }

        function getSubTotal(line) {
            return line.sub_total = _.get(vm.contract, 'is_valid') ? _.get(line, 'sub_total') : (_.get(line, 'price', 0) * _.get(line, 'qte', 0));
        }

        function getContractAmount() {
            if (_.get(vm.contract, 'is_valid')) {
                return vm.contract.taxed_amount;
            } else {
                return _.get(vm.contract, 'details') ? vm.contract.taxed_amount = _.sumBy(vm.contract.details, function (line) {
                    return line.sub_total;
                }) : 0;
            }
        }

        function prePrint() {
            return billingService.saveContract(vm.contract);
        }

        function lotButtonClick(line) {
            const dialog = _.assign({}, require('stock/dialogs/lots-dialog.js'), {
                locals: {
                    lots: _.cloneDeep(!_.isNil(line.lot_numbers) ? line.lot_numbers : []),
                    limit: line.qte,
                    mvt_type: 'OUT',
                    mvt_code: null,
                    mvtDocument: null,
                    documentCls: 'Contract',
                    line: line
                }
            });

            $mdDialog.show(dialog).then(done, _.noop);

            function done(data) {
                line.lot_numbers = data;
            }
        }

        function serialButtonClick(line) {
            const dialog = _.assign({}, require('stock/dialogs/serials-dialog.js'), {
                locals: {
                    serials: _.cloneDeep(!_.isNil(line.serial_numbers) ? line.serial_numbers : []),
                    limit: line.qte,
                    mvt_type: 'OUT',
                    article: line.article,
                    lineWarehouse: line.warehouse,
                    line: line,
                }
            });

            $mdDialog.show(dialog).then(function done(data) {
                line.serial_numbers = data;
            }, _.noop);
        }

        function sumLotsQte(line) {
            return lotService.lotsQteSum(line, 'OUT', line.lot_numbers, 'null', 'Contract');
        }

        function updateWarehouse(line) {
            _.forEach(line.serial_numbers, function (serial) {
                serial.warehouse = line.warehouse;
            });
            _.forEach(line.lot_numbers, function (lot) {
                lot.warehouse = line.warehouse;
            });
        }

        function handlePlan(ev) {
            if (vm.contract.plan) {
                vm.contract.billing_details = _.assign(vm.contract.billing_details, vm.contract.plan.billing_details);
            }
        }

        function removeLine(index) {
            vm.contract.details.splice((vm.query.page - 1) * vm.query.limit + index, 1);
        }

        function validate(is_valid = true) {
            if (!vm.prevent_validate) {
                $mdDialog.show(
                    $mdDialog.confirm()
                        .parent($(document.body))
                        .clickOutsideToClose(true)
                        .title('')
                        .textContent($translate.instant(is_valid ? 'validation_warning' : 'invalidate_warning'))
                        .ariaLabel('validation_warning')
                        .ok($translate.instant('confirm'))
                        .cancel($translate.instant('cancel'))
                ).then(function () {
                    handleValidate();
                })
            }

            function handleValidate() {
                if (!vm.prevent_save) {
                    vm.prevent_save = true;
                    vm.contract.is_valid = is_valid;
                    vm.promise = billingService.updateContract(vm.contract.id, {is_valid}).then(success);
                }

                function success(data) {
                    vm.contract = data;
                    vm.prevent_save = false;
                    if (is_valid) {
                        billingService.handleContractInstance(vm.contract.id).then((data) => {
                            let allow_generate = _.get(data, 'allow_generate');
                            billingService.getContract(vm.contract.id).then((data) => {
                                vm.contract = data;
                                if (allow_generate) {
                                    billingService.generate_stock_document(vm.contract, vm.contract.current_instance);
                                }
                            });
                        });
                    }
                }

            }
        }

        function terminate(ev) {
            $mdDialog.show(_.assign(require('billing/dialogs/contract-terminate-dialog'), {
                targetEvent: ev,
                locals: {
                    terminate_detail: {end_at: moment().format(dateFormat)},
                    termination: true
                }
            })).then(function (data) {
                vm.contract.terminate_detail = data;
                vm.contract.billing_details.canceled = true;
                vm.submit();
            }, _.noop);
        }

        function removeContract(ev) {
            $mdDialog.show(
                $mdDialog.confirm()
                    .parent($(document.body))
                    .clickOutsideToClose(true)
                    .title('')
                    .textContent($translate.instant('delete_warning'))
                    .ariaLabel('delete_warning')
                    .ok($translate.instant('confirm'))
                    .cancel($translate.instant('cancel'))
            ).then(function () {
                if (!vm.prevent_remove) {
                    vm.prevent_remove = true;
                    vm.promise = billingService.deleteContract(vm.contract.id).then(success);
                }
            });

            function success(data) {
                $state.go("app.billing.contract", {})
            }
        }

        function deactivate(ev) {
            $mdDialog.show(_.assign(require('billing/dialogs/contract-terminate-dialog'), {
                    targetEvent: ev,
                    locals: {
                        stand_by_detail: {
                            cycles: 1,
                            paused: true
                        },
                        deactivation: true
                    }
                }
            )).then(function (data) {
                vm.contract.stand_by_detail = data;
                vm.submit();
            }, _.noop);
        }

        function handleBeneficiaryRelations(patient) {
            let relationTypes = [
                {value: 'PARENT', label: "patient_relations_parent"},
                {value: 'CHILD', label: "patient_relations_child"},
                {value: 'SIBLING', label: "patient_relations_sibling"},
                {value: 'SPOUSE', label: "patient_relations_spouse"},
                {value: 'NURSE', label: "patient_relations_nurse"}
            ];
            vm.promise = patientService.getPatientRelations(patient.id)
                .then(data => {
                    vm.relations = _.chain(data).map(e => {
                        let r = e.direction == 'left' ? e.relation : e.inverse_relation;
                        return {
                            relation: r,
                            member: e.direction == 'left' ? e.right : e.left,
                            idx: _.get(_.find(vm.relationTypes, {value: r}), 'index', 10)
                        }
                    }).sortBy('idx').value();
                });
        }

        function getRelationLabel(relation) {
            return _.get(_.find(vm.relationTypes, {value: relation}), 'label', relation);
        }

        function duplicate(next = false) {
            $state.go('app.billing.contract-form', {
                contractId: null,
                duplicateObject: _.assign(_.omit(vm.contract, ['id', 'is_valid', 'beneficiary', 'next_bill_period']), next ? {beneficiary: vm.nextPatient} : {})
            }, {location: 'replace'});
        }

        function handlePricingChange(data) {
            if (vm.contract.tariff) {
                handleRelationsMap();
                _.forEach(vm.contract.details, function (l) {
                    l.price = l.article ? handleArticlePrice(l.article, _.get(l.article, 'sale_price', 0)) || _.get(l.article, 'sale_price', 0) : 0;
                });
            }
        }

        function handleRenewalPeriodChange(ev) {
            vm.contract.billing_details.bill_period = vm.contract.billing_details.renewal_period
        }

        function contractHistoric(row) {
            billingService.contractHistoric(row).then(done, _.noop);

            function done(data) {
                if (data) {
                    vm.reset();
                }
            }
        }

        function renewContract() {
            handleRelationsMap();
            let details = _.map(vm.contract.details, (d) => {
                return _.assignIn(d, {
                    price: vm.contract.tariff ? handleArticlePrice(d.article, _.get(d.article, 'sale_price', 0)) : _.get(d.article, 'sale_price', 0),
                })
            });

            billingService.renewContract(vm.contract, details).then(data => {
                vm.contract = data;
            });
        }

        function handleCommentClick(ev) {
            document.getElementById('comment-input').focus();
        }
    }
})();