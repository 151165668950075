/**
 * Created by Amine on 16/08/2016.
 */
(function () {

    'use strict';

    module.exports = CertificateContainerCtrl;

    CertificateContainerCtrl.$inject = ["$q", "$state", "$stateParams", "certificateService", "$scope", "system", "$transitions", "visitService"];

    function CertificateContainerCtrl($q, $state, $stateParams, certificateService, $scope, system, $transitions, visitService) {
        let vm = this;
        //let dateFormat = system['date_format'].js;
        let currentPatient = $stateParams.pId;
        //let currentVisit = $stateParams.visitId;

        let models = [];
        let certificates = [];

        vm.lock = true;
        vm.showModel = false;

        vm.$onInit = init;

        vm.newCertificate = newCertificate;
        vm.toggleModels = toggleModels;
        vm.constructedTitle = constructedTitle;
        vm.constructedIcon = constructedIcon;
        vm.viewCertificate = viewCertificate;
        vm.pushNewMedicalCertificate = pushNewMedicalCertificate;
        vm.pushNewModelCertificate = pushNewModelCertificate;
        vm.editItem = editItem;
        vm.duplicateItem = duplicateItem;
        vm.deleteItem = deleteItem;
        vm.printItem = printItem;
        vm.listState = listState;
        vm.handleSearch = handleSearch;
        vm.currentEditConsultId = -1;
        vm.currentDuplicated = -1;
        vm.currentPrescription = -1;


        function init() {
            if (_.eq($state.current.name, "app.visit.certificate")) listState();

            let listener = $transitions.onStart({}, toList);

            function toList(transition) {
                let toState = transition.to();

                if (!_.eq(toState.name, "app.visit.certificate.details-list") && _.eq(toState.name, "app.visit.certificate")) {
                    listState()
                }
            }

            $q.all([
                certificateService.getModelCertificateList(),
                certificateService.getMedicalCertificateList(currentPatient)
            ]).then(success);

            function success(results) {
                models = results[0];
                certificates = results[1];

                vm.list = groupCertificates(results[1], sortMedicalCertificate);
            }

            let itemDeletedSubscription = certificateService.itemDeleted.subscribe(itemDeleted);

            function itemDeleted(item) {
                if (item.is_model) _.remove(models, {id: item.id});
                else _.remove(certificates, {id: item.id});

                showModels(vm.showModel);
            }

            let itemEditedSubscription = certificateService.itemEdited.subscribe(itemEdited);

            function itemEdited(pk) {
                resetCurrentIds();
                vm.currentEditConsultId = pk;
            }

            let itemDuplicatedSubscription = certificateService.itemDuplicated.subscribe(itemDuplicated);

            function itemDuplicated(pk) {
                resetCurrentIds();
                vm.currentDuplicated = pk;
            }

            let itemActiveSubscription = certificateService.itemActive.subscribe(itemActive);

            function itemActive(pk) {
                resetCurrentIds();
                vm.currentPrescription = pk;
            }

            let modelAddedSubscription = certificateService.modelAdded.subscribe(modelAdded);

            function modelAdded(item) {
                let index = _.findIndex(models, {id: item.id});
                if (index === -1) models = _.concat(models, item);
                else models[index] = item;

                vm.list = groupCertificates(models, sortModelCertificate);
            }

            let medicalAddedSubscription = certificateService.medicalAdded.subscribe(medicalAdded);

            function medicalAdded(item) {
                let index = _.findIndex(certificates, {id: item.id});
                resetCurrentIds();
                vm.currentEditConsultId = item.id;
                if (index === -1) certificates = _.concat(certificates, item);
                else certificates[index] = item;

                vm.list = groupCertificates(certificates, sortMedicalCertificate);
            }

            let currentPatientSubscription = visitService.currentPatientSubject.subscribe(handlePatient);

            function handlePatient(data) {
                vm.patient = data;
            }

            $scope.$on("$destroy", onDestroy);

            function onDestroy() {
                itemDeletedSubscription.unsubscribe();
                itemEditedSubscription.unsubscribe();
                itemDuplicatedSubscription.unsubscribe();
                itemActiveSubscription.unsubscribe();
                modelAddedSubscription.unsubscribe();
                medicalAddedSubscription.unsubscribe();
                currentPatientSubscription.unsubscribe();

                listener();
            }
        }

        function groupCertificates(items, sortBy) {
            let groups = _.groupBy(items, "category.id");
            return _.sortBy(_.reduce(groups, function (result, value) {
                let category = _.cloneDeep(value[0].category)
                category.items = sortBy(value);
                result.push(category);
                return result;
            }, []), "name");
        }

        function newCertificate(model) {
            vm.currentEditConsultId = -1;
            vm.currentDuplicated = -1;
            vm.currentPrescription = -1;

            certificateService.newState(model);
        }

        function toggleModels() {
            showModels(!vm.showModel);
        }

        function constructedTitle(item) {
            if (_.isNull(item.title) || _.isEmpty(item.title)) return item.certificate_date;
            if (item['is_model']) return item.title;
            return item.title + " (" + item.certificate_date + ")";
        }

        function constructedIcon(item) {
            if (item['is_model']) return "mdi-file-code";
            else return "mdi-file-document";
        }

        function viewCertificate(item) {
            vm.currentEditConsultId = -1;
            vm.currentDuplicated = -1;
            vm.currentPrescription = -1;

            if (item['is_model']) certificateService.useState(item);
            else if (item.visit_id == vm.currentVisit) certificateService.editState(item);
            else {
                $state.go("app.visit.certificate.view", {
                    certificateId: item.id,
                    model: false
                }, {inherit: true}).then($state.reload);

                showModels(false);
            }

        }

        function sortMedicalCertificate(list) {
            return _.sortBy(list, function (item) {
                return -1 * item['sort_date'];
            });
        }

        function sortModelCertificate(list) {
            return _.sortBy(list, function sortFunc(item) {
                return _.chain(item.title).deburr().toLower().value();
            });
        }

        function pushNewMedicalCertificate(item) {
            let index = _.findIndex(certificates, {id: item.id});
            if (_.eq(index, -1)) certificates.push(item);
            else certificates[index] = item;

            certificates = sortMedicalCertificate(certificates);

            showModels(false);
        }

        function pushNewModelCertificate(item) {
            let index = _.findIndex(models, {id: item.id});
            if (index === -1) models.push(item);
            else models[index] = item;

            models = sortModelCertificate(models);
            showModels(true);
        }

        function showModels(show) {
            if (show) {
                vm.showModel = true;
                vm.list = groupCertificates(models, sortModelCertificate);
            } else {
                vm.showModel = false;
                vm.list = groupCertificates(certificates, sortMedicalCertificate);
            }
        }

        function resetCurrentIds() {
            vm.currentEditConsultId = -1;
            vm.currentPrescription = -1;
            vm.currentDuplicated = -1;
        }

        function listState() {
            vm.currentEditConsultId = -1;
            vm.currentDuplicated = -1;
            vm.currentPrescription = -1;
            certificateService.listState();
        }

        function editItem(item) {
            vm.currentPrescription = -1;
            vm.currentDuplicated = -1;
            certificateService.editState(item);
        }

        function duplicateItem(item) {
            vm.currentEditConsultId = -1;
            vm.currentPrescription = -1;
            certificateService.duplicateState(item);
        }

        function deleteItem(item) {
            certificateService.deleteItem(item);
        }

        function printItem(item) {
            certificateService.printItem(item.id);
        }

        function handleSearch(key) {
            vm.items = [];

            key = _.chain(key).toLower().deburr().value();
            if (_.size(key) == 0) vm.items = vm.list;
            else vm.items = _.reduce(_.cloneDeep(vm.list), iterGroups, []);

            function iterGroups(groups, group) {
                group.items = _.reduce(group.items, iterItems, []);
                if (group.items.length > 0) groups.push(group)

                return groups;
            }

            function iterItems(items, item) {
                let title = _.chain(item).get("title").toLower().deburr().value();
                if (_.includes(title, key)) items.push(item);

                return items;
            }
        }
    }

})();