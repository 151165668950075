(function () {
    'use strict';

    const DEFAULT_LANG = (ctx => {
        let keys = ctx.keys();
        let values = keys.map(ctx);
        return keys.reduce((o, k, i) => _.assign(o, values[i]), {});
    })(require.context('../../', true, /^.*lang.json$/));

    const MEMBERS_LANG = _.mapValues(DEFAULT_LANG, value => handleMembersLang(value));
    const PLAYERS_LANG = _.mapValues(DEFAULT_LANG, value => handlePlayersLang(value));
    const FILES_LANG = _.mapValues(DEFAULT_LANG, value => handleFilesLang(value));

    const SUPPORTED_LANG = [
        "default",
        "members",
        "players",
        "files"
    ];

    function handleMembersLang(value) {
        if (_.isString(value)) return _.chain(value)
            .replace(/(^|\s)Le Patient/g, 'L\'Adhérent')
            .replace(/(^|\s)Le patient/g, 'L\'adhérent')
            .replace(/(^|\s)le patient/g, 'l\'adhérent')
            .replace(/Du Patient/g, 'De L\'Adhérent')
            .replace(/Du patient/g, 'De l\'adhérent')
            .replace(/du patient/g, 'de l\'adhérent')
            .replace(/Patient/g, 'Adhérent')
            .replace(/Patients/g, 'Adhérents')
            .replace(/patients/g, 'adhérents')
            .replace(/(^|\s)patient/g, '$1adhérent')
            .value();
        else return _.mapValues(value, sub => handleMembersLang(sub));
    }

    function handlePlayersLang(value) {
        if (_.isString(value)) {
            return _.chain(value)
                .replace(/(^|\s)Le Patient/g, '$1Le Joueur')
                .replace(/(^|\s)Le patient/g, '$1Le joueur')
                .replace(/(^|\s)le patient/g, '$1le joueur')
                .replace(/Du Patient/g, 'Du Joueur')
                .replace(/Du patient/g, 'Du joueur')
                .replace(/du patient/g, 'du joueur')
                .replace(/Patient/g, 'Joueur')
                .replace(/Patients/g, 'Joueurs')
                .replace(/patients/g, 'joueurs')
                .replace(/patients/g, 'joueurs')
                .replace(/(^|\s)patient/g, '$1joueur')
                .value();
        } else return _.mapValues(value, sub => handlePlayersLang(sub));
    }

    function handleFilesLang(value) {
        if (_.isString(value)) {
            return _.chain(value)
                .replace(/(^|\s)Le Patient/g, '$1Le Dossier')
                .replace(/(^|\s)Le patient/g, '$1Le dossier')
                .replace(/(^|\s)le patient/g, '$1le dossier')
                .replace(/Du Patient/g, 'Du Dossier')
                .replace(/Du patient/g, 'Du dossier')
                .replace(/du patient/g, 'du dossier')
                .replace(/Patient/g, 'Dossier')
                .replace(/Patients/g, 'Dossiers')
                .replace(/patients/g, 'dossiers')
                .replace(/patients/g, 'dossiers')
                .replace(/(^|\s)patient/g, '$1dossier')
                .value();
        } else return _.mapValues(value, sub => handleFilesLang(sub));
    }

    class TranslationsConfig {
        constructor(translateProvider, system) {
            translateProvider.translations("default", DEFAULT_LANG);
            translateProvider.translations("members", MEMBERS_LANG);
            translateProvider.translations("players", PLAYERS_LANG);
            translateProvider.translations("files", FILES_LANG);

            const switch_lang = _.get(system, "switch_lang");
            const preferredLanguage = switch_lang && _.includes(SUPPORTED_LANG, switch_lang) ? switch_lang : "default";
            translateProvider.preferredLanguage(preferredLanguage);
            translateProvider.useSanitizeValueStrategy('escaped');
        }

        static create() {
            return new TranslationsConfig(...arguments);
        }
    }

    TranslationsConfig.create.$inject = ['$translateProvider', 'system'];

    module.exports = TranslationsConfig.create;

})();
